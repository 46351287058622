module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-J2MN2D3GLM"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lux Shipping","short_name":"Lux","start_url":"/","background_color":"rebeccapurple","theme_color":"rebeccapurple","legacy":true,"icon":"src/android-chrome-512x512.png","icons":[{"src":"src/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"src/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/favicon-32x32.png","sizes":"32x32","type":"image/png"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9862657cddadad6a7044c9b676061c63"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
